import React from "react";
import {
  Image,
  Box,
  HStack,
  VStack,
  Heading,
  Text,
  Flex,
  Stack,
} from "@chakra-ui/react";
import Card from "../../components/card/Card";
import CardWhite from "../../components/card/CardWhite";
import RoundedAlmir from "./components/RoundedAlmir";

const CURRICULUM = [
  {
    course: "Mestre em Direção Estratégica e Gestão do Conhecimento",
    university: "Universidad International Iberoamericana",
  },
  {
    course: "Bacharelado em Filosofia",
    university: "Centro Universitário Católica de Quixadá",
  },
  {
    course: "Licenciatura em Filosofia",
    university: "Centro Universitário Claretiano",
  },
  {
    course: "Recursos Humanos",
    university: "Instituto ProMinas",
  },
];

export default function Mentor() {
  return (
    <Stack
      position="relative"
      overflow="hidden"
      id="mentor"
      pb={{ base: "40px", md: "198px", xl: "198px" }}
      zIndex={"-20"}
      alignItems={"center"}
    >
      <Image
        src={"./img/bg-mentor.png"}
        position="absolute"
        height="100%"
        width={"100%"}
        objectFit={"cover"}
        objectPosition={"50% 0px"}
        zIndex={"-100"}
        display={{ base: "none", lg: "block" }}
      />
      <Heading
        as="h1"
        height="481.48px"
        fontSize="388.29px"
        lineHeight="31.06px"
        textOverflow="revert"
        position="absolute"
        fontWeight={"400"}
        top="280"
        left="-5"
        width="100vw"
        whiteSpace="nowrap"
        zIndex="-10"
        color={"#D5E2E8CC"}
        display={{ base: "none", lg: "block" }}
      >
        O Mentor
      </Heading>

      <Heading
        as="h1"
        fontSize={{ base: "40px", xl: "100px" }}
        fontWeight={"400"}
        pt={{ base: "32px", xl: "100px" }}
        pb={{ base: "32px", xl: "100px" }}
        display={{ base: "block", lg: "none" }}
      >
        O Mentor
      </Heading>

      <Stack
        spacing={6}
        alignItems={{ base: "center", xl: "flex-start" }}
        width={"100%"}
        justifyContent={"space-between"}
        mt={{ lg: "189px" }}
        px={{ lg: "80px", "2xl": "200px" }}
        zIndex={"100"}
        position={"relative"}
        direction={{ base: "column", xl: "row" }}
      >
        <VStack>
          <RoundedAlmir />

          <Heading
            as="h1"
            fontSize={{ base: "24px", lg: "60px" }}
            fontWeight={"400"}
          >
            Almir Cardoso
          </Heading>

          <Box>
            <CardWhite
              width={"100%"}
              height={"100%"}
              px={{ base: "0px", md: "60px" }}
            >
              <Flex
                height={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
                direction={"column"}
                gap={"32px"}
                pt={{ md: "40px" }}
                pb="24px"
                w={"100%"}
              >
                {CURRICULUM.map((item, index) => (
                  <Box key={index} w={{ xl: "310px" }}>
                    <Text
                      lineHeight={{ lg: "32px" }}
                      fontSize={{ lg: "18px" }}
                      align={"center"}
                    >
                      {item.course}
                    </Text>
                    <Text
                      lineHeight={{ lg: "24px" }}
                      fontSize={{ lg: "16px" }}
                      align={"center"}
                      color={"#4C6580"}
                    >
                      {item.university}
                    </Text>
                  </Box>
                ))}
              </Flex>
            </CardWhite>
          </Box>
        </VStack>

        <VStack>
          <Heading
            as="h1"
            fontSize={{ base: "40px", lg: "60px" }}
            fontWeight={"400"}
          >
            Diferencial
          </Heading>

          <Box>
            <CardWhite width={"100%"} height={"100%"}>
              <Flex
                justifyContent={"center"}
                alignItems={"center"}
                direction={"column"}
                mx={{ lg: "48px" }}
                my={{ lg: "48px" }}
              >
                <Text
                  lineHeight={{ lg: "32px" }}
                  fontSize={{ lg: "18px" }}
                  align={"center"}
                  w={{
                    base: "100%",
                    md: "400px",
                    lg: "340px",
                  }}
                  px={{ base: "32px", lg: "0px" }}
                >
                  Poucos no Brasil apresentam um método trabalhando o
                  Ordenamento Cognitivo Comportamental abordando a Inteligência
                  Emocional a partir da ciência dos temperamentos. Os vários
                  serviços da Floret oferecem as bases deste marco diferencial.
                </Text>
              </Flex>
            </CardWhite>
          </Box>
        </VStack>
      </Stack>
    </Stack>
  );
}
