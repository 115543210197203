import React from "react";
import Header from "./components/header/Header";
import Home from "./pages/home/Home";
import InConstruction from "./pages/in-construction/InConstruction";
import Presentation from "./pages/presentation/Presentation";
import { Box } from "@chakra-ui/react";
import Mentor from "./pages/mentor/Mentor";
import Mission from "./pages/mission/Mission";
import Metodology from "./pages/metodology/Metodology";
import Products from "./pages/products/Products";
import Footer from "./pages/footer/Footer";
import WppButton from "./components/wpp-button/WppButton";

function App() {
  return (
    <Box overflow={"hidden"} position={"relative"}>
      <Header />
      <Home />
      <Presentation />
      <Mentor />
      <Mission />
      <Metodology />
      <Products />
      <Footer />
      <WppButton />
      {/* <InConstruction /> */}
    </Box>
  );
}

export default App;
