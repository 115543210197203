import React from "react";

import { Box, Heading, Stack, Text, Image, Flex } from "@chakra-ui/react";
import Card from "../../components/card/Card";

export default function Metodology() {
  return (
    <Box
      pt={{ base: "0", lg: "128px" }}
      position="relative"
      overflow="visible"
      id="metodologia"
    >
      <Box backgroundColor={{ base: "#F5FBFE", lg: "transparent" }}>
        <Heading
          as="h1"
          height="481.48px"
          fontSize="388.29px"
          lineHeight="31.06px"
          textOverflow="revert"
          position="absolute"
          fontWeight={"400"}
          top="50px"
          left="10"
          width="100vw"
          whiteSpace="nowrap"
          zIndex="-1"
          color={"#D5E2E899"}
          display={{ base: "none", md: "block" }}
        >
          Metodologia
        </Heading>

        <Stack spacing={6} alignItems={"center"}>
          <Heading
            as="h1"
            fontWeight={"400"}
            w={{ base: 280, lg: 621 }}
            fontSize={{ base: "40px", lg: "94px" }}
            textAlign={"center"}
          >
            Metodologia e Diferencial
          </Heading>
          <Box w={{ base: "auto", lg: 575 }}>
            <Card>
              <Box p={"32px"}>
                <Text
                  lineHeight={{ base: "24px", lg: "40px" }}
                  fontSize={{ base: "16px", lg: "24px" }}
                  align={"center"}
                >
                  A Floret utiliza a ciência dos temperamentos como alicerce
                  para os programas de consultoria, mentoria e instrutoria,
                  proporcionando aos clientes um processo para o desenvolvimento
                  da inteligência emocional, isto é, de habilidades vinculadas
                  ao aparato cognitivo comportamental para gerir/liderar com
                  mais eficiência.
                </Text>
              </Box>
            </Card>
          </Box>
        </Stack>
      </Box>

      <Flex w="100%" direction={"column"} alignItems={{ base: "center" }}>
        <Box
          w={{ lg: "668px" }}
          overflow={"hidden"}
          position={{ base: "relative", lg: "absolute" }}
          zIndex={"-1"}
          transform={"auto"}
          translateX={{ lg: "460px" }}
          // right={{ lg: "-260px", xl: "8%" }}
          top={{ base: "48px", lg: "-20px" }}
        >
          <Image
            height={{ base: "435px", lg: "auto" }}
            src="./img/metodologia-bg.png"
          />
        </Box>
      </Flex>
    </Box>
  );
}
