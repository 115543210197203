import React from "react";

import { Box, Heading, HStack, Text, Flex, Stack } from "@chakra-ui/react";
import Card from "../../components/card/Card";

export default function Mission() {
  return (
    <Box
      pt={{ base: "0px:", lg: "64px" }}
      pb={{ base: "0px", lg: "100px" }}
      position="relative"
      overflow="visible"
      id="missaoevalores"
      zIndex={10}
      scrollMarginTop={"35px"}
      backgroundColor={{ base: "#F5FBFE", lg: "transparent" }}
    >
      <Heading
        as="h1"
        height="481.48px"
        fontSize="388.29px"
        lineHeight="31.06px"
        textOverflow="revert"
        position="absolute"
        fontWeight={"400"}
        top="20"
        left="-60"
        width="100vw"
        whiteSpace="nowrap"
        zIndex="-10"
        color={"#D5E2E899"}
        display={{ base: "none", md: "block" }}
      >
        Missão|Valores
      </Heading>

      <Stack
        justifyContent={"center"}
        gap={{ md: "0px", xl: "200px" }}
        direction={{ base: "column", "2xl": "row" }}
        height={"100%"}
      >
        <Flex
          direction={"column"}
          justifyContent={{ base: "center", "2xl": "start" }}
          alignItems={{ base: "center", "2xl": "start" }}
          height={"100%"}
        >
          <Heading
            as="h1"
            fontSize={{ base: "40px", lg: "100px" }}
            py={{ base: "0px", lg: "100px" }}
            pt={{ base: "40px", lg: "0px" }}
            fontWeight={"400"}
          >
            Missão
          </Heading>
          <Box w={{ base: "auto", lg: 612 }} h={{ base: "auto", lg: 288 }}>
            <Card>
              <Box p={"32px"}>
                <Text
                  lineHeight={{ base: "24px", lg: "40px" }}
                  fontSize={{ base: "16px", lg: "24px" }}
                  align={"center"}
                  mb={"32px"}
                >
                  A Floret é uma empresa dedicada ao desenvolvimento humano e ao
                  aprimoramento da inteligência emocional de líderes e gestores,
                  com a finalidade de impulsionamento de carreira, tornando-os
                  eficientes e bem-sucedidos na própria trilha pessoal e
                  profissional.
                </Text>
              </Box>
            </Card>
          </Box>
        </Flex>

        <Flex
          alignItems={{ base: "center", "2xl": "end" }}
          direction={"column"}
          mt={{ base: "0px", lg: "280px" }}
          height={"100%"}
        >
          <Heading
            as="h1"
            fontSize={{ base: "40px", lg: "100px" }}
            py={{ base: "0px", lg: "100px" }}
            pt={{ base: "0px", lg: "0px" }}
            fontWeight={"400"}
          >
            Valores
          </Heading>
          <Box w={{ base: "auto", lg: "698px" }}>
            <Card>
              <Box p={"32px"}>
                <Text
                  lineHeight={{ base: "24px", lg: "82px" }}
                  fontSize={{ base: "16px", lg: "50px" }}
                  fontFamily={{ lg: "Libre Baskerville, serif" }}
                  align={"center"}
                  mb={"32px"}
                >
                  Liberdade, Consistência, Integridade, Virtude,
                  Responsabilidade e Transcendência.
                </Text>
              </Box>
            </Card>
          </Box>
        </Flex>
      </Stack>
    </Box>
  );
}
