import React from "react";
import { Box } from "@chakra-ui/react";

export default function CardWhite({ children, width, height, px }) {
  return (
    <Box
      width={width}
      height={height}
      px={px}
      borderRadius={"24px"}
      border={{ md: "1px solid #DFE5EC" }}
      bg="#FFFFFF"
      mixBlendMode={"normal"}
      backdropFilter={"blur(4px)"}
    >
      {children}
    </Box>
  );
}
