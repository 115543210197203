import React from "react";
import { Box } from "@chakra-ui/react";

export default function Card({ children }) {
  return (
    <Box
      width={"100%"}
      height={"100%"}
      borderRadius={"24px"}
      border={{ lg: "1px solid #DFE5EC" }}
      bg={{ lg: "#32ADE60D" }}
      mixBlendMode={"normal"}
      backdropFilter={"blur(4px)"}
    >
      {children}
    </Box>
  );
}
