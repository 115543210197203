import React from "react";

import { Box, Heading, Stack, Text } from "@chakra-ui/react";
import Card from "../../components/card/Card";

export default function Presentation() {
  return (
    <Box
      pt={{ base: "8px", md: "16px" }}
      pb={"64px"}
      position="relative"
      overflow="visible"
      id="apresentacao"
      scrollMarginTop={"30px"}
      backgroundColor={{ base: "#F5FBFE", md: "none" }}
    >
      <Heading
        as="h1"
        height="480px"
        fontSize="388px"
        lineHeight="31px"
        textOverflow="revert"
        position="absolute"
        fontWeight={"400"}
        top="148"
        left="10"
        whiteSpace="nowrap"
        zIndex="-1"
        color={"#D5E2E899"}
        display={{ base: "none", md: "block" }}
      >
        Apresentação
      </Heading>

      <Stack spacing={1} alignItems={"center"}>
        <Heading
          as="h1"
          fontSize={{ base: "40px", md: "100px" }}
          fontWeight={"400"}
          pt={{ base: "32px", md: "100px" }}
          pb={{ base: "8px", md: "100px" }}
        >
          Apresentação
        </Heading>
        <Box w={{ base: "100%", lg: 946 }}>
          <Card>
            <Box p={"32px"}>
              <Text
                lineHeight={{ base: "24px", md: "40px" }}
                fontSize={{ base: "16px", md: "24px" }}
                align={"center"}
                mb={"32px"}
              >
                A Floret é uma empresa comprometida com o potencial pessoal e
                profissional de pessoas que pretendem elevar as próprias
                capacidades mentais e comportamentais para atingir patamares
                elevados para a própria personalidade. Ela tem a sua origem nos
                estudos realizados junto à Universidad International
                Iberoamericana. Tais estudos vincularam as implicações da
                ciência dos temperamentos sobre o nível de inteligência
                emocional de gestores e profissionais que exercem liderança,
                pretendendo apresentar resultados mais eficiência em seus
                trabalhos no âmbito das relações interpessoais.
              </Text>
              <Text
                lineHeight={{ base: "24px", md: "40px" }}
                fontSize={{ base: "16px", md: "24px" }}
                align={"center"}
              >
                O resultado desses estudos trouxe à tona que as pessoas que
                utilizam os processos vinculados a este método obtêm um nível
                elevado de autocompreensão, elevam as próprias capacidades
                humanas, apresentam alto índice de assertividade nas suas
                intenções e iniciativas pessoais, são predispostas a relações
                interpessoais mais eficientes e a exercício de funções mais
                eficazes.
              </Text>
            </Box>
          </Card>
        </Box>
      </Stack>
    </Box>
  );
}
