import React from "react";
import { Box, Image, useBreakpointValue } from "@chakra-ui/react";

export default function Home() {
  const bgImageSrc = useBreakpointValue({
    base: "./img/home-bg-mobile.png",
    lg: "./img/BGHome.png",
  });

  return (
    <Box>
      <Image
        src={bgImageSrc}
        width={"100%"}
        id="home"
        height={"auto"}
        mt={{ base: "0px", "2xl": "-100px" }}
      ></Image>
    </Box>
  );
}
